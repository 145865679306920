<template>
  <div class="text-center my-16">
    <div>
      <v-icon
        x-large
        class="primary--text"
      >
        mdi-book-open-variant
      </v-icon>
      <h1 class="text-h4 font-weight-light mt-5">
        {{ appName }}
      </h1>
    </div>
    <v-card
      :loading="loading"
      elevation="2"
      class="mx-auto my-12"
      max-width="374"
    >
      <template slot="progress">
        <v-progress-linear
          height="5"
          indeterminate
        />
      </template>

      <v-card-title>Login</v-card-title>

      <v-card-text>
        <v-text-field
          v-model="username"
          :disabled="loading"
          placeholder="Email"
        />
        <v-text-field
          v-model="password"
          type="password"
          :disabled="loading"
          placeholder="Password"
        />
        <v-alert
          v-if="error"
          class="mb-0"
          text
          type="error"
          icon="mdi-alert-circle"
        >
          Error while logging in
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          :disabled="loading"
          color="primary"
          depressed
          @click="submit(username, password)"
        >
          Login
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Login',
  data: function () {
    return {
      error: false,
      loading: false,
      username: process.env.VUE_APP_DEBUG_USERNAME || null,
      password: process.env.VUE_APP_DEBUG_PASSWORD || null,
    }
  },
  computed: {
    ...mapGetters({
      appName: 'app/appName',
    }),
  },
  methods: {
    submit(username, password) {
      this.loading = true
      this.$store.dispatch('auth/login', {
        username,
        password,
      }).then(() => {
        this.error = false
        let route = (this.$route.query.redirect || '/').toString()
        this.$router.push(route)
      }).catch((error) => {
        console.warn(error.message)
        this.error = true
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>
